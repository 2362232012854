import React, { useEffect, useRef } from 'react';
import { Howl } from 'howler';
import { useSelector } from 'react-redux';

const AudioPlayer = ({ isPlaying, setIsPlayinglocal }) => {
    const { audioBlob1, time } = useSelector((state) => state.player);
    const { playingString } = useSelector((state) => state.guitar);
    const [src1, setSrc1] = React.useState(null);
    const [src2, setSrc2] = React.useState(null);
    const soundRef1 = useRef(null);
    const soundRef2 = useRef(null);
    const [playing, setPlaying] = React.useState(1);
    useEffect(() => {
        const sound1 = new Howl({
            src: [src1],
            format: 'mp3',
            // html5: true,
            loop: true,
            volume: 0,
            autoplay: true,
            onload: () => {

                if (sound1._sprite.__default[1].toFixed(2) / 1000 < time) {
                    sound1.seek(0.0);
                    soundRef1.current.fadeSelfIn();

                } else {
                    let t = time;
                    sound1.seek(t);
                    soundRef1.current.fadeSelfIn();

                }
            }
        });
        const source1 = sound1.play();

        const fadeselfout = () => {
            console.log('fade out started on player 1');
            sound1.fade(1, 0, 200, source1);
            sound1.on('fade', function () {
                console.log('fade out finish on player 1');
            })
            setTimeout(() => {
                setSrc1(null);
                soundRef1.current.sound1.unload();
            }, 0);
        }

        const fadeSelfIn = () => {
            if (playing === 1) {
                console.log('fade in started on player 1');
                sound1.fade(0, 1, 200, source1);

                sound1.on('fade', function () {
                    console.log('fade in finish on s1');
                });
            }
        }

        soundRef1.current = { sound1, fadeselfout, fadeSelfIn };



        return () => {
            if (soundRef1.current) {
                soundRef1.current.sound1.unload();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src1]);

    useEffect(() => {
        const sound2 = new Howl({
            src: [src2],
            format: 'mp3',
            // html5: true,
            loop: true,
            volume: 0,
            autoplay: true,
            onload: () => {

                if (sound2._sprite.__default[1].toFixed(2) / 1000 < time) {
                    sound2.seek(0.0);
                    soundRef2.current.fadeSelfIn();
                } else {
                    let t = time;
                    sound2.seek(t);
                    soundRef2.current.fadeSelfIn();
                }
            }
        });

        const source2 = sound2.play();

        const fadeselfout = () => {
            console.log('fade out started on player 2');

            sound2.fade(1, 0, 200, source2);
            sound2.on('fade', function () {
                console.log('fade out finish on player 2');
            })

            setTimeout(() => {
                setSrc2(null);
                soundRef2.current.sound2.unload();
            }, 0)
        }
        const fadeSelfIn = () => {
            if (playing === 2) {
                console.log('fade in started on player 2');
                sound2.fade(0, 1, 200, source2);
                sound2.on('fade', function () {
                    console.log('fade in finish on s2');
                });
            }
        }
        soundRef2.current = { sound2, fadeselfout, fadeSelfIn };

        return () => {
            if (soundRef2.current) {
                soundRef2.current.sound2.unload();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src2]);

    useEffect(() => {
        setIsPlayinglocal(true);
        if (soundRef1.current.sound1.playing() && playing === 1) {
            setSrc2(audioBlob1);
            setPlaying(2);
            setTimeout(() => {
                soundRef1.current.fadeselfout();
            }, 200);
            console.log('playing 2');
        } else if (soundRef2.current.sound2.playing() && playing === 2) {
            setSrc1(audioBlob1);
            setPlaying(1);
            setTimeout(() => {
                soundRef2.current.fadeselfout();
            }, 200);
            console.log('playing 1');
        } else {
            setSrc2(null);
            setSrc1(audioBlob1);
            setPlaying(1)
            console.log('playing 1');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioBlob1])

    useEffect(() => {
        playing === 1 ? setPlaying(1) : setPlaying(2)
        return () => {

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playingString])


    useEffect(() => {
        console.log(isPlaying, playing);
        if (soundRef1.current) {
            if (isPlaying) {
                if (playing === 1) {
                    soundRef1.current.sound1.play();
                } else if (playing === 2) {
                    soundRef2.current.sound2.play();
                }
            } else {
                soundRef1.current.sound1.pause();
                soundRef2.current.sound2.pause();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlaying]);
};

export default AudioPlayer;