import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("userToken");
const user = localStorage.getItem("user");

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: user,
        token: token,
        isFetching: false,
        error: true,
        errMsg: "",
    },
    reducers: {
        loginStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        loginSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.token = action.payload.token;
            state.user = JSON.stringify(action.payload.user);

            localStorage.setItem("userToken", state.token);
            localStorage.setItem("user", state.user);
        },
        loginFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errMsg = action.payload;
        },
        registerStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        registerSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.token = action.payload.token;

            localStorage.setItem("userToken", state.token);
        },
        registerFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errMsg = action.payload;
        },
        
        logOut: (state, action) => {
            state.user = null;
            state.token = null;

            localStorage.removeItem("userToken");
            localStorage.removeItem("user");
        },
    }
})
export const {
    logOut,
    loginStart,
    loginSuccess,
    loginFailure,
    registerSuccess,
    registerStart,
    registerFailure,
    
} = authSlice.actions;

export default authSlice.reducer;