import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Backdrop from '../Assets/backdrop.png';
import Logo from '../Assets/logo-wikiStrings.svg';

const Forgot = () => {
    const navigate = useNavigate();
    const [formdata, setFormdata] = React.useState({
        email: 'abc@email.com',
    })
    return (
        <div className='overflow-hidden' style={{ 'backgroundColor': "rgb(14,17,19)", minheight: '100vh' }}>
            <Container fluid>
                <Row>
                    <Col className='px-md-5'>
                        <img src={Logo} alt="Logo" />
                        <div className='d-flex p-5 justify-content-center flex-column w-100 text-white h-100 poppins'>
                            <h1>
                                Forgot password
                            </h1>
                            <form className='p-md-5' autoComplete={"off"}>
                                <p className='mb-5'>
                                    Enter your email for the verification processes will send 4 digits code to your email.
                                </p>
                                <div className='d-flex flex-column gap-5'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor="email" style={{ color: 'rgba(153, 153, 153, 1)' }}>Email</label>
                                        <div className='d-flex align-items-center gap-2' >
                                            <input type="text" value={formdata.email} onChange={(e) => setFormdata({ ...formdata, email: e.target.value })} style={{ background: "transparent" }} className='border-none border rounded-5 w-100 fs-6 green-placeholder p-3' placeholder='Enter your email address' />
                                        </div>
                                    </div>
                                    <button onClick={() => navigate('verification')} className='p-3 rounded-5 green-background fw-bold fs-5 text-white' type='submit'>
                                        Continue
                                    </button>

                                </div>
                            </form>
                        </div>
                    </Col>
                    <Col className='d-flex d-none d-md-flex justify-content-center p-5' style={{ height: '100vh' }}>
                        <img src={Backdrop} alt="backdrop color" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Forgot