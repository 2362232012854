import { AiFillPlayCircle, AiFillPauseCircle } from 'react-icons/ai';
import React, { useState, useEffect, useRef } from 'react';
import { fetchFile } from '../utils/Functions';
import { useDispatch, useSelector } from 'react-redux';
import { updateTimer, setAudio1, setAudio2 } from '../Redux/Slices/PlayerSlice';


const AudioPlayer = (props) => {
    const dispatch = useDispatch();
    const { time, audioBlob1, audioBlob2 } = useSelector((state) => state.player);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef();
    const audioRef2 = useRef();
    useEffect(() => {
        let donefetch = false;
        if (!donefetch) {
            setSeek();
        }
        console.log(props.data, props.data2);
        return () => {
            donefetch = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioBlob1, audioBlob2])

    const setBlob1 = () => {
        const audio = audioRef.current;
        if (props.src) {
            dispatch(setAudio2(audioBlob1));
            fetchFile(props.src, dispatch, setAudio1);
        }
        audio.addEventListener('timeupdate', updateTime);
        audio.addEventListener('ended', handleEnd);
        return () => {
            audio.removeEventListener('timeupdate', updateTime);
            audio.removeEventListener('ended', handleEnd);
        };
    }

    useEffect(() => {
        setBlob1();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.src]);

    useEffect(() => {
        const audio = audioRef2.current;
        audio.addEventListener('timeupdate', updateTime);
        audio.addEventListener('ended', handleEnd);
        return () => {
            audio.removeEventListener('timeupdate', updateTime);
            audio.removeEventListener('ended', handleEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.src2]);

    const playPause = async () => {
        const isSrc = props.src;
        const audio = audioRef.current;
        const audio2 = audioRef2.current;

        if (isSrc) {
            try {
                if (audio.paused) {
                    props.setIsPlayinglocal(true);
                    await audio.play();
                    await audio2.play();
                } else {
                    props.setIsPlayinglocal(false);
                    await audio.pause();
                    await audio2.pause();
                }
            } catch (error) {
                console.error("Error during play/pause:", error);
            }
        }
    };


    const setSeek = () => {
        const audio = audioRef.current;
        const audio2 = audioRef2.current;
        if (audio2.duration < time || audio.duration < time) {
            dispatch(updateTimer(0));
            audio.currentTime = 0;
            audio2.currentTime = 0;
        } else {
            audio.currentTime = time;
            audio2.currentTime = time;
        }
    }

    const updateTime = () => {
        const audio = audioRef.current;
        try {
            setCurrentTime(audio.currentTime);
            setDuration(audio.duration);
        } catch (e) {
            console.log(e);
        }
    };

    const handleEnd = () => {
        props.setIsPlayinglocal(false);
        setCurrentTime(0);
    };

    const handleSeek = (event) => {
        const audio = audioRef.current;
        const seekTime = parseFloat(event.target.value);

        const updateSlider = () => {
            if (Math.abs(audio.currentTime - seekTime) > 0.01) {
                audio.currentTime = seekTime;
                setCurrentTime(seekTime);
                requestAnimationFrame(updateSlider);
            }
        };

        updateSlider();
    };

    const calculatePercentage = (value, max) => (value / max) * 100;
    // ...

    <input
        className='custom-range '
        style={{ width: "50vw" }}
        type="range"
        disabled={!Number.isFinite(duration)}
        min="0"
        max={duration}
        value={currentTime}
        onChange={handleSeek}
        onInput={handleSeek}
    />




    return (
        <>
            <audio ref={audioRef} src={audioBlob1} autoPlay id={props.audioID2} loop
                onTimeUpdate={(e) => {
                    if (e.target.currentTime > 1) {
                        dispatch(updateTimer(e.target.currentTime))
                    }
                }}
            />
            <audio ref={audioRef2} src={audioBlob2} autoPlay id={props.audioID} loop
                onTimeUpdate={(e) => {
                    if (e.target.currentTime > 1) {
                        dispatch(updateTimer(e.target.currentTime))
                    }
                }}
            />
            {
                props.noshow ? "" :
                    <div className='d-flex justify-content-between align-items-center p-2'>
                        <button className='border-none bg-transparent' type={'button'} onClick={() => playPause()}>
                            {props.isPlayinglocal ? (
                                <AiFillPauseCircle className='text-warning bg-white rounded-5 ' style={{ fontSize: '40px' }} />
                            ) : (
                                <AiFillPlayCircle className='text-warning bg-white rounded-5 ' style={{ fontSize: '40px' }} />
                            )}
                        </button>
                        <div className='quicksand' style={{ color: 'rgba(255, 255, 255, 0.44)', width: '20vw' }}>
                            {currentTime?.toFixed(0)} / {duration?.toFixed(0)}
                        </div>
                        {(
                            <div className='d-flex justify-content-center align-items-center custom-range-container '>
                                <input
                                    className='custom-range'
                                    type="range"
                                    disabled={Number.isFinite(duration)}
                                    min="0"
                                    max={duration || 30}
                                    value={currentTime}
                                    onChange={handleSeek}

                                    style={{
                                        width: '50vw',
                                        background: `linear-gradient(to right, rgba(250, 205, 102, 1) ${calculatePercentage(
                                            currentTime,
                                            duration
                                        )}%, #d3d3d3 ${calculatePercentage(
                                            currentTime,
                                            duration
                                        )}%)`,
                                    }}
                                />
                            </div>


                        )}
                    </div>
            }

        </>
    );
};

export default AudioPlayer;