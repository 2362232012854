import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import zigzag from '../Assets/backzingzag.svg';

import profile from '../Assets/profile.png';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { updateProfile } from '../Redux/ApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { errorHandler } from '../utils/errorHandler';

const MyProfile = () => {
    const dispatch = useDispatch();
    const { isFetching, error, errMsg } = useSelector((state) => state.profile);
    const user = JSON.parse(localStorage.getItem('user'));
    const [isUpdated, setIsUpdated] = React.useState(false);

    const [formdata, setFormdata] = React.useState({
        fullname: user?.fullname || '',
        email: user?.email || '',
        mobile: user?.mobile || '',
        password: user?.password || ''
    });

    const handleUpdate = async (e) => {
        e.preventDefault();
        await updateProfile(dispatch, { fullname: formdata.fullname, email: formdata.email, mobile: formdata.mobile, password: formdata.password });
        setIsUpdated(true);
        swal("Good job!", "You clicked the button!", "success");
    }

    if (error && !isFetching && isUpdated) {
        swal("Oops", errorHandler(errMsg), "error");
        setIsUpdated(false);
    }
    return (
        <div className='d-flex flex-column justify-content-between' style={{ height: '100vh' }} >
            {/* <Topbar /> */}
            <img src={zigzag} alt="" className='position-absolute top-50 start-50 translate-middle w-100' />
            <div className='bg-dark h-100'>
                <Container className='d-flex flex-column gap-4'>
                    <h1 className='quicksand green-text my-4'>
                        My Profile
                    </h1>
                    <div className='d-flex align-items-center gap-3'>
                        <img src={profile} alt="" className='border border-5 rounded-circle border-success' style={{ height: "100px", width: '100px' }} />
                        <div className='green-text mt-3'>
                            <h3>
                                User ID:
                            </h3>
                            <p>
                                {user?.fullname}
                            </p>
                        </div>
                    </div>
                    <form className='text-white' onSubmit={handleUpdate}>
                        <Row>
                            <Col lg={6} sm={12}>
                                <div>
                                    <label htmlFor="name">Full name</label>
                                    <InputGroup className="mb-3" size="lg">
                                        <Form.Control
                                            id='name'
                                            placeholder={user?.fullname}
                                            aria-label="Joe Done"
                                            value={formdata?.fullname}
                                            onChange={(e) => setFormdata({ ...formdata, fullname: e.target.value })}
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div>
                                    <label htmlFor="email">Email address</label>
                                    <InputGroup className="mb-3" size="lg">
                                        <Form.Control
                                            id='email'
                                            placeholder={user?.email}
                                            value={formdata?.email}
                                            onChange={(e) => setFormdata({ ...formdata, email: e.target.value })}
                                            aria-label="micheal05@gmail.com"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} sm={12}>
                                <div>
                                    <label htmlFor="mobile">Phone number</label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                                        <Form.Control
                                            id='mobile'
                                            placeholder={user?.mobile}
                                            value={formdata?.mobile}
                                            onChange={(e) => setFormdata({ ...formdata, mobile: e.target.value })}
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div>
                                    <label htmlFor="password">Password</label>
                                    <InputGroup className="mb-3" size="lg">
                                        <Form.Control
                                            placeholder="*******"
                                            id='password'
                                            aria-label="*******"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <Container fluid className='d-flex justify-content-center align-items-center mt-4'>
                            <Button type='submit' disabled={isFetching} className='py-2 px-5 green-background border-none text-white rounded'>
                                Update profile
                            </Button>
                        </Container>
                    </form>
                </Container>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default MyProfile