import { configureStore } from "@reduxjs/toolkit";

import authReducer from './Slices/AuthSlice';
import guitarReducer from "./Slices/GuitarSlice";
import playerReducer from './Slices/PlayerSlice';
import profileSlice from "./Slices/ProfileSlice";

export default configureStore({
    reducer: {
        auth: authReducer,
        guitar: guitarReducer,
        profile: profileSlice,
        player: playerReducer
    }
})