import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AudioSpectrum extends Component {
  constructor(props) {
    super(props);

    this.animationId = null;
    this.audioContext = null;
    this.audioEle = null;
    this.audioCanvas = null;
    this.playStatus = null;
    this.canvasId = this.props.id || this.getRandomId(50);
    this.mediaEleSource = null;
    this.analyser = null;
    this.gainValue = this.props.gainValue;

    // Bind methods to the instance
    this.initAudioEvents = this.initAudioEvents.bind(this);
    this.drawSpectrum = this.drawSpectrum.bind(this);
    this.setupAudioNode = this.setupAudioNode.bind(this);
    this.prepareElements = this.prepareElements.bind(this);
    this.prepareAPIs = this.prepareAPIs.bind(this);
  }

  getRandomId(len) {
    const characters = '1234567890-qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM';
    const charactersLength = characters.length;
    let result = '';

    for (let i = 0; i < len; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters[randomIndex];
    }

    return result;
  }

  setupAudioNode(audioEle) {
    if (!this.analyser) {
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.smoothingTimeConstant = 0.8;
      this.analyser.fftSize = 2048;
    }

    if (!this.mediaEleSource) {
      this.mediaEleSource = this.audioContext.createMediaElementSource(audioEle);
      this.gainNode = this.audioContext.createGain();
      this.gainNode.gain.value = this.gainValue;

      this.gainNode.connect(this.audioContext.destination);
      this.mediaEleSource.connect(this.gainNode);
      this.mediaEleSource.connect(this.analyser);
      // this.mediaEleSource.connect(this.audioContext.destination);
    }

    return this.analyser;
  }

  prepareAPIs() {
    window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
    window.requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame;
    window.cancelAnimationFrame = window.cancelAnimationFrame || window.webkitCancelAnimationFrame || window.mozCancelAnimationFrame || window.msCancelAnimationFrame;

    try {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 48100,
        latencyHint: 'interactive'
      });
    } catch (e) {
      console.warn(e);
    }
  }

  initAudioEvents() {
    const audioEle = this.audioEle;

    if (audioEle) {
      audioEle.onpause = (e) => {
        this.playStatus = 'PAUSED';
      };

      audioEle.onplay = (e) => {
        this.playStatus = 'PLAYING';

        this.prepareAPIs();

        const analyser = this.setupAudioNode(this.audioEle);

        this.drawSpectrum(analyser);
      };
    }
  }

  fade(audioContext, gainNode, startValue, endValue, duration) {
    if (gainNode) {
      gainNode.gain.setValueAtTime(startValue, audioContext.currentTime);
      gainNode.gain.linearRampToValueAtTime(endValue, audioContext.currentTime + duration);
    }
  };

  drawSpectrum(analyser) {
    const cwidth = this.audioCanvas.width;
    const cheight = this.audioCanvas.height - this.props.capHeight;
    const capYPositionArray = [];
    const ctx = this.audioCanvas.getContext('2d');
    let gradient = ctx.createLinearGradient(0, 0, 0, 300);

    if (this.props.meterColor.constructor === Array) {
      const stops = this.props.meterColor;
      const len = stops.length;

      for (let i = 0; i < len; i++) {
        gradient.addColorStop(stops[i]['stop'], stops[i]['color']);
      }
    } else if (typeof this.props.meterColor === 'string') {
      gradient = this.props.meterColor;
    }

    const drawMeter = () => {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);

      const step = Math.round(array.length / this.props.meterCount);
      ctx.clearRect(0, 0, cwidth, cheight + this.props.capHeight);

      for (let i = 0; i < this.props.meterCount; i++) {
        const value = array[i * step];

        if (capYPositionArray.length < Math.round(this.props.meterCount)) {
          capYPositionArray.push(value);
        }

        ctx.fillStyle = this.props.capColor;

        if (value < capYPositionArray[i]) {
          const preValue = --capYPositionArray[i];
          const y = (270 - preValue) * cheight / 270;
          ctx.fillRect(i * (this.props.meterWidth + this.props.gap), y, this.props.meterWidth, this.props.capHeight);
        } else {
          const y = (270 - value) * cheight / 270;
          ctx.fillRect(i * (this.props.meterWidth + this.props.gap), y, this.props.meterWidth, this.props.capHeight);
          capYPositionArray[i] = value;
        }

        ctx.fillStyle = gradient;
        const y = (270 - value) * cheight / 270 + this.props.capHeight;
        ctx.fillRect(i * (this.props.meterWidth + this.props.gap), y, this.props.meterWidth, cheight);
      }

      this.animationId = requestAnimationFrame(drawMeter);
    };

    // Always start the animation, regardless of audio playback state
    this.animationId = requestAnimationFrame(drawMeter);
  }


  prepareElements() {
    const { audioId, audioEle } = this.props;

    if (!audioId && !audioEle) {
      return;
    } else if (audioId) {
      this.audioEle = document.getElementById(audioId);
    } else {
      this.audioEle = audioEle;
    }

    this.audioCanvas = document.getElementById(this.canvasId);
  }



  componentDidMount() {
    this.prepareElements();
    this.initAudioEvents();
  }

  render() {
    return (
      <canvas
    
        id={this.canvasId}
        width={this.props.width}
        height={this.props.height}
      />
    );
  }
}

AudioSpectrum.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number,
  height: PropTypes.number,
  audioId: PropTypes.string,
  audioEle: PropTypes.object,
  capColor: PropTypes.string,
  capHeight: PropTypes.number,
  gainValue: PropTypes.number,
  meterWidth: PropTypes.number,
  meterCount: PropTypes.number,
  meterColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      stop: PropTypes.number,
      color: PropTypes.string,
    })),
  ]),
  gap: PropTypes.number,
};


AudioSpectrum.defaultProps = {
  width: 300,
  height: 200,
  capColor: '#FFF',
  capHeight: 2,
  gainValue: 1,
  meterWidth: 2,
  meterCount: 40 * (2 + 2),
  meterColor: [
    { stop: 0, color: 'red' },
    { stop: 0.5, color: '#0CD7FD' },
    { stop: 1, color: '#f00' },
  ],
  gap: 10, // gap between meters
};


export default AudioSpectrum;
