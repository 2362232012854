import React from 'react';
import { Container } from 'react-bootstrap';
import zigzag from '../Assets/backzingzag.svg';
import { VscDebugDisconnect } from "react-icons/vsc";


const NotFound = () => {
    return (
        <div className='d-flex flex-column zigzagback ' style={{ height: '100vh', width: '100vw' }}>
            <div className='bg-dark h-100'>
                <img src={zigzag} alt="" className='position-absolute top-50 start-50 translate-middle w-100' />
                <Container className='d-flex flex-column gap-4 justify-content-center align-items-center w-100 h-100'>
                    <h1 className='quicksand green-text my-4 fs-1'>
                        <VscDebugDisconnect className='' style={{fontSize:'30vh'}}/>
                        404 Page Not Found
                    </h1>
                </Container>
            </div>

        </div>
    )
}

export default NotFound