import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import zigzag from '../Assets/backzingzag.svg';
import { FaAmazon } from "react-icons/fa";
import { FaPause } from "react-icons/fa6";
import { selectGuitar, selectGuitar2 } from '../Redux/Slices/GuitarSlice';
// import songimg from '../Assets/songimg.png';
// import cardPerson from '../Assets/cardplayimg.png';
// import AudioWaveform from './WaveForm';
// import AudioSpectrum from 'react-audio-spectrum';
import AudioSpectrum from './AudioSpectrometer';
import AudioPlayer from './AudioPlayer';
import AudioPlayer2 from './AudioPlayerHowl';
import { useSelector, useDispatch } from 'react-redux';
import { clearBlobs, clearTime } from '../Redux/Slices/PlayerSlice';

const StringPlayCard = ({ noplaymode, data, data2, isPlayinglocal, setIsPlayinglocal }) => {
    // Run this effect only once when the component mounts
    const [src, setSrc] = useState("");
    const [src2, setSrc2] = useState("");
    const dispatch = useDispatch();
    const [diskOne, setDiskOne] = useState();
    const [diskTwo, setDiskTwo] = useState();
    const { playingString } = useSelector((state) => state.guitar);
    React.useEffect(() => {
        setSrc2(data2.guitarName !== undefined ? `${data2?.strings[playingString]?._id}` : "");
        setSrc(data?.strings[playingString]?._id);
        if (data !== diskOne && data !== diskTwo) {
            setDiskOne(data);
            setDiskTwo(data2);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playingString, data, data2]);

    React.useEffect(() => {
        dispatch(clearBlobs());
        dispatch(clearTime());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playingString])

    return (
        <Container fluid>
            <div className='position-relative pt-3 pt-md-5 text-white fixed_box w-100 back rounded-4 playing_box_height' style={{ background: "rgba(96, 158, 175, 1)", overflow: 'hidden' }}>
                <div className='px-3 px-md-5'>
                    <p className="d-flex align-items-center justify-content-between">
                        <h6 className='manrope'>
                            {!isPlayinglocal ? 'Currently Paused' : 'Currently Playing'}
                        </h6>
                        <Row className='' style={{ width: '120px' }}>
                            <Col
                                onClick={() => {
                                    dispatch(selectGuitar(data2));
                                    dispatch(selectGuitar2(data));
                                }}
                                className='p-0 m-0 d-flex justify-content-between align-items-center flex-column gap-1'>
                                <img src={diskOne?.guitarPic} width={'20px'} height={'20px'} alt="" className={`rounded-circle ${!isPlayinglocal ? "" : 'spinn'} ${diskOne === data ? 'glow-green border-greener' : 'glow-grey border-greyer'}  disk`} />
                                {diskOne === data && <button onClick={() => { window.open(data?.affiliateLink, '_blank') }} className="w-full btn btn-success p-1 m-0" style={{ fontSize: '8px' }}><FaAmazon /> Get info</button>}
                            </Col>
                            <Col
                                onClick={() => {
                                    dispatch(selectGuitar(data2));
                                    dispatch(selectGuitar2(data));
                                }}
                                className='p-0 m-0 d-flex justify-content-between align-items-center flex-column gap-1'>
                                {diskTwo?.guitarPic && <img src={diskTwo?.guitarPic} width={'20px'} height={'20px'} alt="" className={`rounded-circle ${!isPlayinglocal ? "" : 'spinn'} ${diskOne === data2 ? 'glow-green border-greener' : 'glow-grey border-greyer'} disk`} />}
                                {diskTwo === data && <button onClick={() => { window.open(data?.affiliateLink, '_blank') }} className="w-full btn btn-success p-1 m-0" style={{ fontSize: '8px' }}><FaAmazon /> Get info</button>}
                            </Col>
                        </Row>
                        {/* <MdSpatialAudio className='fs-1' /> */}
                    </p>
                    <h1 className='quicksand fs-5 text-truncate w-100 guitar-img' style={{ fontWeight: '700' }}>
                        <span className='glow-green-text'>
                            {data?.guitarName}
                        </span>
                        <span className='glow-grey-text'>
                            {data2?.guitarName !== undefined ? ` VS ${data2.guitarName}` : ""}
                        </span>
                    </h1>
                    <div className='quicksand guitar-img'>
                        <p className='w-100'>
                            {data?.brand}
                        </p>
                    </div>
                </div>
                <AudioPlayer2 src={src} isPlaying={isPlayinglocal} setIsPlayinglocal={setIsPlayinglocal} />
                <img src={zigzag} alt="" className='position-absolute top-0 end-0 ' style={{ transform: 'rotate(125deg) scale(1.5)' }} />
                <FaPause className={`${isPlayinglocal && 'd-none'} position-absolute top-50 end-50`} style={{ fontSize: '50px' }} />
                <div className={!isPlayinglocal && 'd-none'} style={{ overflow: 'hidden' }}>
                    <div className='position-absolute z-2 p-2 right-mid' style={{ zIndex: '0' }}>
                        <AudioSpectrum
                            id="canvas"
                            height={78}
                            width={400}
                            audioId={'playerOne'}
                            meterWidth={5}
                            meterCount={500}
                            meterColor={[
                                { stop: 0, color: '#10e55b' },
                                { stop: 0.5, color: '#00c586' },
                                { stop: 1, color: '#00a297' }
                            ]}
                            gap={4}
                            gainValue={0}
                            capColor='#00a297'
                        />
                    </div>
                    <div className='position-absolute z-1 p-2 right-mid' style={{ zIndex: '40' }}>
                        {/* Set a higher z-index (z-1) for the second div */}
                        <AudioSpectrum
                            id="canvas2"
                            height={78}
                            width={400}
                            audioId={'playerTwo'}
                            meterWidth={5}
                            meterCount={500}
                            meterColor={[
                                { stop: 0, color: '#888885' },
                                { stop: 0.5, color: '#7f817c' },
                                { stop: 1, color: '#747a73' }
                            ]}
                            gap={4}
                            gainValue={0}
                            capColor="#747a73"
                        />
                    </div>
                </div>


                {/* <div className='position-absolute bottom-0 w-100 bottom-0 d-flex justify-content-around align-items-center ' style={{ height: '28%', borderTopLeftRadius: '0', borderTopRightRadius: '0', borderBottomLeftRadius: '0', borderBottomRightRadius: '0', backdropFilter: 'blur(68px)', zIndex: '100' }}>
                    <Row>
                        <Col className='d-none align-items-center gap-1 d-sm-flex d-md-flex' lg={2}>
                            <div>
                                <img src={data?.guitarPic} alt="" className='rounded' style={{ height: "50px", aspectRatio: '1' }} />
                            </div>
                            <div className='quicksand mt-3'>
                                <h6>
                                    {data?.guitarName}
                                </h6>
                                <p>
                                    {data?.artist}
                                </p>
                            </div>
                        </Col>
                        <Col lg={10} sm={12}>
                            <div className='d-flex flex-column justify-content-center w-100' style={{ height: "50px" }}>
                                <AudioPlayer isPlayinglocal={isPlayinglocal} setIsPlayinglocal={setIsPlayinglocal} src={src} src2={src2} audioID={'playerTwo'} audioID2={'playerOne'} />
                            </div>
                        </Col>
                    </Row>
                </div> */}
                <div className='position-absolute bottom-0 w-100 bottom-0 d-flex justify-content-around align-items-center ' style={{ height: '28%', borderTopLeftRadius: '0', borderTopRightRadius: '0', borderBottomLeftRadius: '0', borderBottomRightRadius: '0', backdropFilter: 'blur(68px)', zIndex: '100' }}>
                    <Row>
                        <Col lg={12} md={9} sm={12}>
                            <div className='d-flex flex-column justify-content-center w-100' style={{ height: "50px" }}>
                                <AudioPlayer data={data} data2={data2} isPlayinglocal={isPlayinglocal} setIsPlayinglocal={setIsPlayinglocal} src={src} src2={src2} audioID={'playerTwo'} audioID2={'playerOne'} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export default StringPlayCard