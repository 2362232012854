// Layout.js
import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

const Layout = ({ children }) => {
    return (
        <div className='d-flex justify-content-end flex-column'>
            <Navbar />
            <div style={{ minHeight: "100vh", paddingTop: '80px' }} >{children}</div>
            <Footer />
        </div >
    );
};

export default Layout;
