import React from 'react'
import { useNavigate } from 'react-router-dom';
import Card from '../Components/Card';
import { Container, Row, Col, Spinner, Dropdown, Alert, Button } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropButton from '../Components/Button';
import Button2 from '../Components/Button2';
import { useSelector } from 'react-redux';
import StringPlayCard from '../Components/StringPlayCard';
import { namer } from '../utils/Functions';


const Playing = () => {
    const navigate = useNavigate();
    const { guitars, guitar, guitar2, playingString } = useSelector((state) => state.guitar);
    const [guitarsFiltred, setGuitarsFiltred] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [allBrands, setAllBrands] = React.useState([]);
    const [isPlayinglocal, setIsPlayinglocal] = React.useState(true);
    const [allMaterial, setAllMaterial] = React.useState([]);
    const [brand, setBrand] = React.useState();
    const [material, setMaterial] = React.useState();

    const getAllBrands = (guitars) => {
        const brandsSet = new Set();

        guitars?.forEach(guitar => {
            brandsSet.add(guitar.brand);
        });

        return Array.from(brandsSet);
    }

    React.useEffect(() => {
        console.log("Audio player mounted");

        return () => {
            console.log("Audio player unmounted");
        };
    }, []);

    const getAllMaterials = (guitars) => {
        const brandsSet = new Set();

        guitars?.forEach(guitar => {
            brandsSet.add(guitar.material);
        });

        return Array.from(brandsSet);
    }

    const setAllGuitar = () => {
        setGuitarsFiltred(guitars.filter(item => item.type === guitar.type));
        setAllBrands(getAllBrands(guitarsFiltred));
        setAllMaterial(getAllMaterials(guitarsFiltred));
        setIsLoading(false);
    }

    React.useEffect(() => {
        if (guitars.length === 0 || guitar.length === 0 || guitar2.length === 0) {
            navigate('/');
        }
        setAllGuitar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        setAllBrands(getAllBrands(guitars));
        setAllMaterial(getAllMaterials(guitars));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guitarsFiltred]);


    React.useEffect(() => {
        if (brand) {
            setGuitarsFiltred(guitars.filter(item => item.type === guitar.type).filter(item => item.brand === brand));
        }
        if (material) {
            setGuitarsFiltred(guitars.filter(item => item.type === guitar.type).filter(item => item.material === material));
        }
        if (brand && material) {
            setGuitarsFiltred(guitars.filter(item => item.type === guitar.type).filter(item => item.brand === brand).filter(item => item.material === material));
        }
        if (brand === 'All') {
            setGuitarsFiltred(guitars.filter(item => item.type === guitar.type).filter(item => item.material === material));
        }
        if (material === 'All') {
            setGuitarsFiltred(guitars.filter(item => item.type === guitar.type).filter(item => item.brand === brand));
        }
        if (brand === 'All' && material === 'All') {
            setAllGuitar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand, material])

    return (
        <div className='d-flex flex-column' style={{ height: '100vh' }}>
            <div className='d-flex flex-column flex-grow p-1 p-md-2 gap-4' style={{ minHeight: '100vh' }}>
                {/* <StringDescCard /> */}
                {
                    isLoading ? <Spinner animation="border" /> :
                        <Container>
                            <Row>
                                <Col xs={4} sm={4} md={4} lg={2}>
                                    <Dropdown as={ButtonGroup} className='w-100'>
                                        <Button variant="warning" className='w-100 text-truncate' style={{ fontSize: '12px' }}>
                                            {namer(guitar?.strings[playingString]?.name)}
                                        </Button>
                                        <Dropdown.Toggle split variant="warning" id="dropdown-split-basic" />
                                        <Dropdown.Menu>
                                            {
                                                guitar?.strings?.map((string, index) => (
                                                    playingString !== index ? <DropButton playingString={playingString} setIsPlayinglocal={setIsPlayinglocal} label={string.name} key={index} index={index} /> : ""
                                                    // <Dropdown.Item key={index} className='text-black'>{string.name}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col xs={4} sm={4} md={4} lg={2}>
                                    <Dropdown as={ButtonGroup} className='w-100'>
                                        <Button variant="warning" className='w-100 text-truncate' style={{ fontSize: '12px' }}>
                                            {brand ? brand : 'Brand'}
                                        </Button>
                                        <Dropdown.Toggle split variant="warning" id="dropdown-split-basic" />
                                        <Dropdown.Menu>
                                            <div onClick={() => setBrand("All")}>
                                                <Dropdown.Item className='text-black text-truncate w-100'>
                                                    All
                                                </Dropdown.Item>
                                            </div>
                                            {
                                                allBrands?.map((brand, index) => (
                                                    <Button2 isPlayinglocal={isPlayinglocal} setIsPlayinglocal={setIsPlayinglocal} label={brand} key={index} index={index} func={setBrand} />
                                                    // <Dropdown.Item key={index} className='text-black'>{string.name}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col xs={4} sm={4} md={4} lg={2}>
                                    <Dropdown as={ButtonGroup} className='w-100' >
                                        <Button variant="warning" className='w-100 text-truncate' style={{ fontSize: '12px' }}>
                                            {material ? material : 'Material'}
                                        </Button>
                                        <Dropdown.Toggle split variant="warning" id="dropdown-split-basic" />
                                        <Dropdown.Menu>
                                            <div onClick={() => setMaterial("All")}>
                                                <Dropdown.Item className='text-black text-truncate w-100'>
                                                    All
                                                </Dropdown.Item>
                                            </div>
                                            {
                                                allMaterial?.map((material, index) => (
                                                    <Button2 label={material} key={index} index={index} func={setMaterial} />
                                                    // <Dropdown.Item key={index} className='text-black'>{string.name}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Container>
                }
                <Container className='whitespace-nowrap overflow-auto scrollbar-hide'>
                    {
                        guitarsFiltred.length < 1 ?
                            <Alert variant='info' >
                                This Varient Doesn't contain any String
                            </Alert> :
                            <Row>
                                {guitarsFiltred.map((guitar, index) => (
                                    <Col xs={4} sm={4} md={4} lg={2} className='d-flex' key={index}>
                                        <Card data={guitar} guitar={index} selector={true} />
                                    </Col>
                                ))}
                            </Row>
                    }

                </Container>
                {
                    isLoading ?
                        <Spinner animation="border" />
                        : <StringPlayCard isPlayinglocal={isPlayinglocal} setIsPlayinglocal={setIsPlayinglocal} noplaymode={false} data={guitar} data2={guitar2} />
                }

            </div>
        </div>
    )
}

export default Playing