import { createSlice } from "@reduxjs/toolkit";

const guitarSlice = createSlice({
    name: 'guitar',
    initialState: {
        isFetching: false,
        guitars: [],
        slovedGuitar: [],
        guitar: {},
        guitar2: {},
        guitarType: '',
        playingString: 3,
        error: false,
        errMsg: "",
        loading: '0'
    },
    reducers: {
        getGuitars: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getGuitarsSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.guitars = action.payload.guitars;
        },
        getGuitarsFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errMsg = action.payload;
        },
        selectString: (state, action) => {
            state.playingString = action.payload;
        },
        grabGuitar: (state, action) => {
            state.slovedGuitar = [...state.slovedGuitar, action.payload];
            state.guitars = [...state.slovedGuitar, action.payload];
            // console.log("logger", action.payload);
        },
        selectGuitar: (state, action) => {
            state.guitar = action.payload;
        },
        selectGuitar2: (state, action) => {
            state.guitar2 = action.payload;
        },
        setLoader: (state, action) => {
            state.loading = action.payload;
        },
        eraseGuitars: (state) => {
            state.guitar = {};
            state.guitar2 = {};
        }
    }
})

export const {
    getGuitars,
    getGuitarsFailure,
    getGuitarsSuccess,
    selectGuitar,
    selectString,
    selectGuitar2,
    eraseGuitars,
    grabGuitar,
    setLoader
} = guitarSlice.actions;

export default guitarSlice.reducer;