import React, { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signin from './Pages/Signin';
import Signup from './Pages/Signup';
import RegisterLayout from './Layouts/Register';
import HomeLayout from './Layouts/Home';
import Home from './Pages/Home';
import Playing from './Pages/Playing';
import Profile from './Pages/MyProfile'
import Reset from './Pages/Forgot'
import ProtectedRoute from './utils/ProtectedRoute';
import Verification from './Pages/Verification';
import NotFound from './Pages/404NotFound';
const Howitworks = lazy(() => import('./Pages/HowItWorks'));
const Mission = lazy(() => import('./Pages/Mission'));
const AboutUs = lazy(() => import('./Pages/AboutUs'));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Routes>
          <Route path="/signin" element={<RegisterLayout><Signin /></RegisterLayout>} />
          <Route path="/signup" element={<RegisterLayout><Signup /></RegisterLayout>} />
          <Route path="/reset" >
            <Route index element={<RegisterLayout><Reset /></RegisterLayout>} />
            <Route path="verification" element={<RegisterLayout><Verification /></RegisterLayout>} />
          </Route>
          <Route path="/howitworks" element={<HomeLayout><Howitworks /></HomeLayout>} />
          <Route path="/mission" element={<HomeLayout><Mission /></HomeLayout>} />
          <Route path="/aboutus" element={<HomeLayout><AboutUs /></HomeLayout>} />
          <Route path="/" element={<HomeLayout><Home /></HomeLayout>} />
          <Route path="/playing" element={<HomeLayout><Playing /></HomeLayout>} />
          <Route path="/profile" element={<ProtectedRoute><HomeLayout><Profile /></HomeLayout></ProtectedRoute>} />
          <Route path="*" element={<RegisterLayout><NotFound /></RegisterLayout>} />
        </Routes>
      </Router>
    </Suspense >
  );
}

export default App;
