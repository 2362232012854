import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
    name: 'guitar',
    initialState: {
        isFetching: false,
        profile: {},
        error: false,
        errMsg: "",
    },
    reducers: {
        getProfile: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getProfileSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.guitars = action.payload.guitars;
        },
        getProfileFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errMsg = action.payload;
        },
        updateStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updateSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.user = JSON.stringify(action.payload.user);
            localStorage.setItem("user", state.user);
        },
        updateFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errMsg = action.payload;
        },
    }
})

export const {
    getGuitars,
    getGuitarsFailure,
    getGuitarsSuccess,
    updateStart,
    updateSuccess,
    updateFailure
} = profileSlice.actions;

export default profileSlice.reducer;