import React from 'react';
import { selectString } from '../Redux/Slices/GuitarSlice';
import { Dropdown } from 'react-bootstrap';
import { namer } from '../utils/Functions';
import { useDispatch } from 'react-redux';
import { updateTimer, clearBlobs } from '../Redux/Slices/PlayerSlice';

const Button = ({ label, index, isPlayinglocal, setIsPlayinglocal }) => {
    const dispatch = useDispatch();

    const handleChangeString = () => {
        setIsPlayinglocal(!isPlayinglocal);
        dispatch(selectString(index));
        dispatch(updateTimer(0));
        dispatch(clearBlobs());
    }
    return (
        <Dropdown.Item className='text-black' onClick={() => handleChangeString()}>
            {namer(label)}
        </Dropdown.Item>
    )
}

export default Button