import React from 'react';

const Layout = ({ children }) => {
    return (
        <div>
            <div style={{ minHeight: '100vh' }}>{children}</div>
        </div>
    );
};

export default Layout;
