import React from 'react'
import Card from '../Components/Card';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { getAllGuitars, getAcousticGuitar, getElectricGuitar } from '../Redux/ApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { errorHandler } from '../utils/errorHandler';
import swal from 'sweetalert';
import { eraseGuitars } from '../Redux/Slices/GuitarSlice';
import { clearTime } from '../Redux/Slices/PlayerSlice';

const Home = () => {
    const dispatch = useDispatch();

    const [searchData, setSearchData] = React.useState('')
    const { isFetching, error, errMsg, slovedGuitar, loading } = useSelector((state) => state.guitar);
    const [isGuitarsFetched, setIsGuitarsFetched] = React.useState(false);
    const [guitarType, setGuitarType] = React.useState(1);
    const [guitarsFiltered, setGuitarsFiltered] = React.useState([]);

    const performSearch = (e) => {
        e.preventDefault();
        if (guitarType === 1) {
            getAcousticGuitar(dispatch, searchData);
        } else if (guitarType === 2) {
            getElectricGuitar(dispatch, searchData);
        }
    }
    const filter = (inputArray, guitarType) => {
        if (guitarType === 1) {
            setGuitarsFiltered(inputArray.filter(item => item.type === "acoustic"));
        }
        else if (guitarType === 2) {
            setGuitarsFiltered(inputArray.filter(item => item.type === "electric"));
        }
    }

    React.useEffect(() => {
        dispatch(eraseGuitars());
        dispatch(clearTime());
        const handleSubmit = async () => {
            getAllGuitars(dispatch, slovedGuitar, loading);
            filter(slovedGuitar, guitarType);
            setIsGuitarsFetched(true);
        }
        handleSubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    React.useEffect(() => {
        filter(slovedGuitar, guitarType);
    }, [guitarType, slovedGuitar]);

    if (error && !isFetching && isGuitarsFetched) {
        swal("Oops", errorHandler(errMsg), "error");
        setIsGuitarsFetched(false);
    }

    return (
        <div className='d-flex flex-column' >
            <div className='d-flex flex-column flex-grow   p-1 p-md-5 gap-4' style={{ minHeight: '100vh' }}>
                <div className='p-5 text-white w-100 back rounded'>
                    <div className="d-flex align-items-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                            <path d="M11.5336 2.41676C11.7992 2.17939 12.2008 2.17939 12.4664 2.41676L14.8406 4.53818C14.9588 4.64382 15.1094 4.7062 15.2677 4.71510L18.4466 4.89382C18.8023 4.91382 19.0862 5.19775 19.1062 5.55342L19.2849 8.73230C19.2938 8.89060 19.3562 9.04118 19.4618 9.15941L21.5832 11.5336C21.8206 11.7992 21.8206 12.2008 21.5832 12.4664L19.4618 14.8406C19.3562 14.9588 19.2938 15.1094 19.2849 15.2677L19.1062 18.4466C19.0862 18.8023 18.8023 19.0862 18.4466 19.1062L15.2677 19.2849C15.1094 19.2938 14.9588 19.3562 14.8406 19.4618L12.4664 21.5832C12.2008 21.8206 11.7992 21.8206 11.5336 21.5832L9.15941 19.4618C9.04118 19.3562 8.89060 19.2938 8.7323 19.2849L5.55342 19.1062C5.19775 19.0862 4.91382 18.8023 4.89382 18.4466L4.71510 15.2677C4.70620 15.1094 4.64382 14.9588 4.53818 14.8406L2.41676 12.4664C2.17939 12.2008 2.17939 11.7992 2.41676 11.5336L4.53818 9.15941C4.64382 9.04118 4.70620 8.89060 4.71510 8.7323L4.89382 5.55342C4.91382 5.19775 5.19775 4.91382 5.55342 4.89382L8.73230 4.71510C8.89060 4.70620 9.04118 4.64382 9.15941 4.53818L11.5336 2.41676Z" fill="#13A8F2" />
                        </svg>
                        <h6 className='manrope' style={{ fontSize: '12px' }}>
                            Verified Strings
                        </h6>
                    </div>
                    <h1 className='quicksand' style={{ fontWeight: '600' }}>
                        Welcome to WikiStrings
                    </h1>
                    <div className='quicksand'>
                        <p style={{ fontSize: '12px' }}>
                            Select The String You Like !!
                        </p>
                        <div className='p-2 bg-dark d-inline-block rounded-5'>
                            <button onClick={() => setGuitarType(1)} className={`${guitarType === 1 ? "text-white green-background border-none" : "green-text border-none bg-transparent"}  rounded-5 py-1 px-3`}>
                                Acoustic
                            </button>
                            <button onClick={() => setGuitarType(2)} className={`${guitarType === 2 ? "text-white green-background border-none" : "green-text border-none bg-transparent"}  rounded-5 py-1 px-3`}>
                                Electric
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-4 px-2'>
                    <form className='bg-light border py-2 px-4 rounded d-flex' onSubmit={performSearch}>
                        <input onChange={event => setSearchData(event.target.value)} type="text" className='bg-transparent border-none w-100 text-black' placeholder="Search Music or Backsound" />
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7669 20.7552C16.7311 20.7552 20.7554 16.7309 20.7554 11.7666C20.7554 6.80239 16.7311 2.77808 11.7669 2.77808C6.80263 2.77808 2.77832 6.80239 2.77832 11.7666C2.77832 16.7309 6.80263 20.7552 11.7669 20.7552Z" stroke="#A7AAB5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M18.0186 18.4851L21.5426 22" stroke="#A7AAB5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </form>

                </div>
                <Container>
                    {
                        slovedGuitar.length > 0 ?
                            guitarsFiltered?.length === 0 ?
                                <Alert variant='info' >
                                    This Varient Doesn't contain any Guitar
                                </Alert> :
                                <Row className='text-white'>
                                    {guitarsFiltered?.map((guitar, index) => (
                                        <Col xs={4} sm={4} md={4} lg={2} className='d-flex' key={index}>
                                            <Card data={guitar} guitar={index} />
                                        </Col>
                                    ))}
                                </Row> :
                            <Alert variant='success' >
                                Loading...{loading !== '0' && loading}
                                Strumming the digital strings, tuning into perfection. Hold tight for your musical journey to load!
                            </Alert>
                    }
                </Container>
            </div>
        </div>
    )
}

export default Home


