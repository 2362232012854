import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import wikiStrings from '../Assets/logo-wikiStrings.svg';
import { FaTiktok, FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
    return (
        <footer className=" text-white p-3" style={{
            background: 'linear-gradient(rgba(255,255,255,1),rgba(0, 50, 80, 1))',
        }}>
            <Container className='px-5'>
                <div className='quicksand d-flex justify-content-between flex-wrap gap-2 text-black' >
                    <Link to={'/'}>
                        <p> Home</p>
                    </Link>
                    <Link to={'/aboutus'}>
                        <p> About us</p>
                    </Link>
                    <Link to={'/mission'}>
                        <p> Mission</p>
                    </Link>
                    <Link to={'/howitworks'}>
                        <p> How It Works</p>
                    </Link>
                    <p> Privacy policy</p>
                    {/* <p> Terms of Use</p> */}
                </div>
                <Row className=''>
                    <Col md={4} className='d-flex align-items-center'>
                        <Image src={wikiStrings} alt="wiki strings" fluid style={{ maxHeight: '50px' }} />
                    </Col>
                    <Col md={4} className='d-flex justify-content-center align-items-center'>
                        <div className='d-flex gap-2'>
                            <span onClick={() => window.open('https://www.facebook.com/WikiStrings', '_blank')}>
                                <FaFacebook className='bg-secondary p-2 rounded-circle' style={{ height: '40px', width: '40px' }} />
                            </span>
                            <span onClick={() => window.open('https://www.instagram.com/wikistrings/', '_blank')}>
                                <FaInstagram className='bg-secondary p-2 rounded-circle' style={{ height: '40px', width: '40px' }} />
                            </span>
                            <span>
                                <FaTiktok className='bg-secondary p-2 rounded-circle' style={{ height: '40px', width: '40px' }} />
                            </span>
                        </div>
                    </Col>
                    <Col md={4} className=' d-flex  align-items-center justify-content-md-end'>
                        <h6 className='quicksand fw-bolder text-black'> ©️ 2000-{new Date().getFullYear()}, All Rights Reserved</h6>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer