import AWS from 'aws-sdk'
import axios from './axiosInstance';
import { grabGuitar, setLoader } from '../Redux/Slices/GuitarSlice';

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function extractFileKey(srctofetch) {
    if (srctofetch.startsWith("https://wikistrings.s3.amazonaws.com/uploads/")) {
        return srctofetch.split("/").pop().split(".")[0];
    }
}

export const namer = (name) => {
    const names = {
        "lowFrequency": "Bass Arpegio",
        "fingerStyle": "Fingerpicking",
        "pick": "Pick Arpegio",
        "strumming": "Strumming",
        "chord": "Single Chord"
    };
    return names[name] || "Label not found";
}

export const fetchFile = async (srctofetch, dispatch, updateTo) => {
    try {
        const db = await openIndexedDB();
        const transaction = db.transaction(['audio'], 'readonly');
        const objectStore = transaction.objectStore('audio');

        const request = objectStore.get(srctofetch);

        request.onsuccess = (event) => {
            const result = event.target.result;
            if (result) {
                const audioBlob = result.blob; // Assuming 'value' is the property storing the blob
                // downloadBlob(audioBlob, `audio_${srctofetch}.mp3`);
                // You can adjust the filename as needed
                const url = window.URL.createObjectURL(audioBlob);
                dispatch(updateTo(url));
            } else {
                console.error(`Audio data for fileId ${srctofetch} not found in IndexedDB.`);
            }
        };

        request.onerror = (event) => {
            console.error('Error reading audio data from IndexedDB: ', event.target.error);
        };
    } catch (error) {
        console.error('Error opening database for reading: ', error);
    }


}






const fetchFileFromS3 = async (fileKey) => {
    AWS.config.update({
        accessKeyId: 'AKIA5KUIXHESBJDJWH7R',
        secretAccessKey: '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',
        region: 'us-east-1',
    });

    const s3 = new AWS.S3();

    const params = {
        Bucket: 'wikistrings',
        Key: `uploads/${extractFileKey(fileKey)}.mp3`,
    };

    try {
        const data = await s3.getObject(params).promise();
        if (!data.Body) {
            throw new Error(`Missing mp3 file data from S3 response.`);
        }
        return data.Body;
    } catch (error) {
        throw error;
    }
};


const downloadAndStoreAudioFiles = async (audioData) => {
    try {
        const AudioPromises = [];

        for (const audio of audioData) {
            const exists = await new Promise((resolve) => {
                ifExistOnDB(audio._id, (exists) => {
                    resolve(exists);
                });
            });

            if (!exists) {
                const promise = (async () => {
                    try {
                        const fileData = await fetchFileFromS3(audio.file);
                        const blob = new Blob([fileData], { type: 'audio/mp3' });
                        await storeBlobInIndexedDB(blob, audio._id);
                    } catch (error) {
                        console.error('Error fetching/storing audio file:', error);
                        throw error;
                    }
                })();
                AudioPromises.push(promise);
            }
        }

        await Promise.all(AudioPromises);
        console.log('All promises resolved.');
        return true

    } catch (error) {
        console.error('Error downloading and storing audio files:', error);
        return false
    }
};



const ifExistOnDB = async (id, callback) => {
    try {
        const db = await openIndexedDB();
        const transaction = db.transaction(['audio'], 'readonly');
        const objectStore = transaction.objectStore('audio');
        const request = objectStore.get(id);

        request.onsuccess = (event) => {
            const result = event.target.result;
            if (result) {
                callback(true);
            } else {
                callback(false);
            }
        };

        request.onerror = (event) => {
            console.error('Error reading audio data from IndexedDB: ', event.target.error);
            callback(false);
        };
    } catch (error) {
        console.error('Error opening database for reading: ', error);
        callback(false);
    }
};

const openIndexedDB = async () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('AudioData', 1);

        request.onerror = (event) => {
            console.error('Error opening database: ', event.target.error);
            reject(event.target.error);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('audio')) {
                db.createObjectStore('audio', { keyPath: '_id' });
            }
        };

        request.onsuccess = (event) => {
            const db = event.target.result;
            resolve(db);
        };

        request.onblocked = (event) => {
            reject('Database access blocked');
        };
    });
};


const storeBlobInIndexedDB = async (blob, fileId) => {
    try {
        const db = await openIndexedDB();

        const transaction = db.transaction(['audio'], 'readwrite');
        const objectStore = transaction.objectStore('audio');

        const objectToStore = { _id: fileId, blob: blob, key: fileId };

        const putRequest = objectStore.put(objectToStore);

        await new Promise((resolve, reject) => {
            putRequest.onsuccess = () => {
                resolve();
            };

            putRequest.onerror = (event) => {
                console.error('Error adding audio file to IndexedDB:', event.target.error);
                reject(event.target.error);
            };
        });

        // Do not abort the transaction here
    } catch (error) {
        console.error('Error storing audio files in IndexedDB: ', error);
    }
};

const writeStrings = (guitars, dispatch, preguitars, loading, length) => {
    guitars.map((guitar, index) => {
        try {
            const exists = preguitars.find(prevGuitar => prevGuitar.id === guitar.id);
            if (!exists) {
                downloadAndStoreAudioFiles(guitar.strings).then((res) => {
                    if (res) {
                        dispatch(grabGuitar(guitar))
                        // console.log((((index + 1) / length) * 100).toFixed(0));
                        //  console.log((index + 1 / length) * 100);
                        dispatch(setLoader((((index + 1) / length) * 100).toFixed(0)))
                    }
                })
            }
        } catch (e) {
            console.warn(e);
        }
        return 0
    })
}

export const GetAllGuitars = async (dispatch, slovedGuitar, loading) => {
    try {
        const { data } = await axios.get("/api/data/guitar");
        writeStrings(data.guitars, dispatch, slovedGuitar, loading, data.guitars.length)
    } catch (error) {
        console.error(error);
    }
}



