import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import facebook from '../Assets/Facebook.svg';
// import apple from '../Assets/apple.svg';
// import google from '../Assets/google.svg';
import Backdrop from '../Assets/15437.jpg';
import Logo from '../Assets/logo-wikiStrings.svg';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Redux/ApiCalls';
import swal from 'sweetalert';
import { errorHandler } from '../utils/errorHandler';

const Signin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isFetching, error, errMsg } = useSelector((state) => state.auth);
    const [isloggedin, setIsloggedin] = React.useState(false);
    const [isHidden, setIsHidden] = React.useState(true);
    const [formdata, setFormdata] = React.useState({
        email: '',
        password: ''
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = formdata;
        await login(dispatch, { email, password });
        if (!error) {
            swal("Success", "Logged in", "success");
            navigate('/');
        }
        setIsloggedin(true);
    }
    const handleSignup = () => {
        navigate('/signup');
    }
    if (error && !isFetching && isloggedin) {
        swal("Oops", errorHandler(errMsg), "error");
        setIsloggedin(false);
    }

    return (
        <div className='overflow-y-scroll' style={{ height: "100vh" }}>
            <Container fluid>
                <Row>
                    <Col className='px-md-5'>
                        <img src={Logo} alt="Logo" onClick={() => navigate('/')} />
                        <div className='d-flex p-5 justify-content-xxl-start flex-column w-100 text-black h-100 poppins m-lg-3'>
                            <form className='p-md-5' autoComplete={"off"} onSubmit={handleSubmit}>
                                <h1>
                                    Sign in
                                </h1>
                                <div className='mb-5'>
                                    If you don't have an account register <br />
                                    You can <button type='button' onClick={() => handleSignup()} className='green-text bg-transparent border-none'>Register here!</button>
                                </div>
                                <div className='d-flex flex-column gap-5'>
                                    <div className='d-flex flex-column border-green '>
                                        <label htmlFor="email" style={{ color: 'rgba(153, 153, 153, 1)' }}>Email</label>
                                        <div className='d-flex align-items-center gap-2 shadow rounded p-2' >
                                            <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.49414 0.67627H15.5059C16.3297 0.67627 17 1.34654 17 2.17041V10.8292C17 11.6531 16.3297 12.3233 15.5059 12.3233H1.49414C0.670271 12.3233 0 11.6531 0 10.8292V2.17041C0 1.34654 0.670271 0.67627 1.49414 0.67627ZM1.68914 1.67236L1.88856 1.83841L7.90719 6.85013C8.25071 7.13614 8.74936 7.13614 9.09281 6.85013L15.1114 1.83841L15.3109 1.67236H1.68914ZM16.0039 2.39148L11.1001 6.4749L16.0039 9.73853V2.39148ZM1.49414 11.3272H15.5059C15.7465 11.3272 15.9478 11.1556 15.9939 10.9284L10.3014 7.13992L9.73018 7.61559C9.37377 7.91236 8.93685 8.06074 8.49997 8.06074C8.06308 8.06074 7.62619 7.91236 7.26976 7.61559L6.69853 7.13992L1.00605 10.9284C1.05221 11.1557 1.25348 11.3272 1.49414 11.3272ZM0.996094 9.73853L5.89993 6.47493L0.996094 2.39148V9.73853Z" fill="#37C25E" />
                                            </svg>
                                            <input required type="text" value={formdata.email} onChange={(e) => setFormdata({ ...formdata, email: e.target.value })} style={{ background: "transparent" }} className='border-none w-75 fs-6 green-placeholder text-black' placeholder='Enter your email address' />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column border-green'>
                                        <label htmlFor="email" style={{ color: 'rgba(153, 153, 153, 1)' }}>Password</label>
                                        <div className='d-flex align-items-center justify-content-between  gap-2 shadow rounded p-2' >
                                            <div className='d-flex gap-2 w-100'>
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.0032 7.2296V4.53742C13.0104 3.31927 12.5238 2.14798 11.6553 1.29383C10.8155 0.457706 9.71632 0 8.55223 0C8.53421 0 8.51259 0 8.49457 0C6.01142 0.00360399 3.99319 2.03625 3.99319 4.53742V7.2296C3.05616 7.34132 2.3714 8.12699 2.3714 9.07844V15.1259C2.3714 16.1531 3.19311 17 4.22024 17H12.7797C13.8068 17 14.6286 16.1531 14.6286 15.1259V9.07844C14.6249 8.13059 13.9402 7.34132 13.0032 7.2296ZM4.71039 4.53742H4.71399C4.71399 2.43269 6.41147 0.709985 8.49817 0.709985H8.50178C9.49287 0.706381 10.4443 1.09922 11.1471 1.79839C11.8787 2.52279 12.286 3.51028 12.2788 4.53742V7.2332H11.4859V4.53742C11.4931 3.71931 11.1687 2.93364 10.5885 2.35701C10.0407 1.8092 9.29826 1.49926 8.5234 1.49926H8.50178C6.84394 1.49926 5.50326 2.86156 5.50326 4.53381V7.2332H4.71039V4.53742ZM10.7687 4.53742V7.2332H6.22766V4.53742C6.22766 3.26161 7.24399 2.22366 8.50538 2.22366H8.52701C9.11085 2.22366 9.67307 2.45792 10.0875 2.87238C10.5272 3.31206 10.7759 3.91393 10.7687 4.53742ZM13.9402 15.1367C13.9402 15.7674 13.4284 16.2792 12.7977 16.2792H4.23466C3.60396 16.2792 3.0922 15.7674 3.0922 15.1367V9.09646C3.0922 8.46576 3.60396 7.954 4.23466 7.954H12.7977C13.4284 7.954 13.9402 8.46576 13.9402 9.09646V15.1367Z" fill="#37C25E" />
                                                    <path d="M9.74516 11.8933C9.58659 11.3347 9.07843 10.9526 8.49818 10.9526C7.78099 10.9526 7.19714 11.5329 7.19714 12.2537C7.19714 12.8339 7.57917 13.3421 8.13778 13.5007V14.5098C8.13778 14.708 8.29996 14.8702 8.49818 14.8702C8.6964 14.8702 8.85858 14.708 8.85858 14.5098V13.5007C9.54694 13.3024 9.94699 12.5816 9.74516 11.8933ZM8.49818 12.8303C8.17743 12.8303 7.91794 12.5708 7.91794 12.2501C7.91794 11.9293 8.17743 11.6698 8.49818 11.6698C8.81894 11.6698 9.07842 11.9293 9.07842 12.2501C9.07842 12.5708 8.81894 12.8303 8.49818 12.8303Z" fill="#37C25E" />
                                                </svg>

                                                <input required type={isHidden ? "password" : 'text'} value={formdata.password} onChange={(e) => setFormdata({ ...formdata, password: e.target.value })} className='border-none w-100 input-transparent green-placeholder text-black' placeholder='Enter your Password' />
                                            </div>
                                            <button type='button' onClick={() => setIsHidden(!isHidden)} className='bg-transparent border-none'>
                                                {isHidden ? <AiFillEyeInvisible className='text-black ' /> : <AiFillEye className='text-black ' />}
                                            </button>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between' style={{ fontSize: '12px' }}>
                                        <div>
                                            <div className='d-flex gap-1 ' >
                                                <input required type="checkbox" className='input-transparent' name="remember" id="remember" />
                                                <label htmlFor="remember" >Remember me</label>
                                            </div>
                                        </div>
                                        {/* <div>
                                            <button onClick={() => navigate('/reset')} className='border-none bg-transparent' style={{ color: 'rgba(153, 153, 153, 1)' }}>Forgot password?</button>
                                        </div> */}
                                    </div>
                                    <button className='p-3 rounded-5 green-background fw-bold fs-5 ' type='submit' disabled={isFetching}>
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Col>
                    <Col className='d-flex d-none d-md-flex justify-content-center p-5' style={{ height: '100vh' }}>
                        <img src={Backdrop} alt="backdrop color" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Signin