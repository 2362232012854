import React from 'react';
import { signup } from '../Redux/ApiCalls';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Backdrop from '../Assets/15437.jpg';
import Logo from '../Assets/logo-wikiStrings.svg';
import email from '../Assets/email.svg';
import user from '../Assets/user.svg';
import password from '../Assets/password.svg';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
    const { isFetching, error } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [issignedup, setIssignedup] = React.useState(false);
    const [isHidden, setIsHidden] = React.useState(true);
    const [isHidden2, setIsHidden2] = React.useState(true);
    const isValidEmail = (email) => {
        // Regular expression for validating email addresses
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const [formdata, setFormdata] = React.useState({
        email: '',
        username: '',
        password: '',
        phone: '',
        confirmpassword: '',
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { email, username, password, phone } = formdata;
        if (!email || !username || !password || !phone) {
            return Swal.fire({
                icon: "error",
                title: "Validation error",
                text: "Please fill all fields!",
            });
        }
        else if (!isValidEmail(formdata?.email)) {
            return Swal.fire({
                icon: "error",
                title: "Validation error",
                text: "Please enter proper email address!",
            });
        } else if (formdata?.phone.length < 8) {
            return Swal.fire({
                icon: "error",
                title: "Validation error",
                text: "Please fill mobile number!",
            });
        }
        if (formdata.password === formdata.confirmpassword) {
            await signup(dispatch, { email: email.trim(), fullname: username.trim(), password: password.trim(), mobile: phone.trim() });
            if (!error) {
                Swal.fire({
                    icon: "success",
                    title: "success",
                    text: "Your account is created!",
                });
                // swal("Success", "Your account is created", "success");
                navigate('/');
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops",
                text: "Password and confirm password does'nt match!",
            });
        }
        setIssignedup(true);
    }
    if (error && !isFetching && issignedup) {
        setIssignedup(false);
    }
    return (
        <div className='overflow-scroll' style={{ height: '100vh' }}>
            <Container fluid>
                <Row>
                    <Col className='px-md-5'>
                        <img src={Logo} alt="Logo" onClick={() => navigate('/')} />
                        <div className='d-flex p-5 justify-content-xxl-start flex-column w-100 text-black h-100 poppins'>

                            <form className='p-md-5' autoComplete={"off"} onSubmit={handleSubmit}>
                                <h1>
                                    Sign up
                                </h1>
                                <p className='mb-5'>
                                    If you already have an account register<br />
                                    You can <button onClick={() => navigate('/signin')} className='green-text border-none bg-transparent'>Login here!</button>
                                </p>
                                <div className='d-flex flex-column gap-5'>
                                    <div className='d-flex flex-column border-green '>
                                        <label htmlFor="email" style={{ color: 'rgba(153, 153, 153, 1)' }}>Email</label>
                                        <div className='d-flex align-items-center gap-2 shadow rounded p-2' >
                                            <img src={email} alt="email logo" />
                                            <input required type="email" value={formdata.email} onChange={(e) => setFormdata({ ...formdata, email: e.target.value })} style={{ background: "transparent" }} className='border-none w-75 fs-6 green-placeholder text-black' placeholder='Enter your email address' />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column border-green '>
                                        <label htmlFor="email" style={{ color: 'rgba(153, 153, 153, 1)' }}>Phone</label>
                                        <div className='d-flex align-items-center gap-2 shadow rounded p-2' >
                                            <img src={email} alt="email logo" />
                                            <input required type="number" value={formdata.phone} onChange={(e) => setFormdata({ ...formdata, phone: e.target.value })} style={{ background: "transparent" }} className='border-none w-75 fs-6 green-placeholder text-black' placeholder='Enter your Phone number' />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column border-green '>
                                        <label htmlFor="username" style={{ color: 'rgba(153, 153, 153, 1)' }}>Fullname</label>
                                        <div className='d-flex align-items-center gap-2 shadow rounded p-2' >
                                            <img src={user} alt="user" />
                                            <input required type="text" value={formdata.username} onChange={(e) => setFormdata({ ...formdata, username: e.target.value })} style={{ background: "transparent" }} className='border-none w-75 fs-6 green-placeholder text-black' placeholder='Enter your user name' />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column border-green'>
                                        <label htmlFor="email" style={{ color: 'rgba(153, 153, 153, 1)' }}>Password</label>
                                        <div className='d-flex align-items-center justify-content-between  gap-2 shadow rounded p-2' >
                                            <div className='d-flex gap-2 w-100'>
                                                <img src={password} alt="password" />
                                                <input required type={isHidden ? "password" : 'text'} value={formdata.password} onChange={(e) => setFormdata({ ...formdata, password: e.target.value })} className='border-none w-100 input-transparent green-placeholder text-black' placeholder='Enter your Password' />
                                            </div>
                                            <button type='button' onClick={() => setIsHidden(!isHidden)} className='bg-transparent border-none'>
                                                {isHidden ? <AiFillEyeInvisible className='text-green ' /> : <AiFillEye className='text-green ' />}
                                            </button>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column border-green'>
                                        <label htmlFor="email" style={{ color: 'rgba(153, 153, 153, 1)' }}>Confirm Password</label>
                                        <div className='d-flex align-items-center justify-content-between  gap-2 shadow rounded p-2' >
                                            <div className='d-flex gap-2 w-100'>
                                                <img src={password} alt="password" />
                                                <input required type={isHidden2 ? "password" : 'text'} value={formdata.confirmpassword} onChange={(e) => setFormdata({ ...formdata, confirmpassword: e.target.value })} className='border-none w-100 input-transparent green-placeholder text-black' placeholder='Confirm your Password' />
                                            </div>
                                            <button type='button' onClick={() => setIsHidden2(!isHidden2)} className='bg-transparent border-none'>
                                                {isHidden2 ? <AiFillEyeInvisible className='text-green ' /> : <AiFillEye className='text-green ' />}
                                            </button>
                                        </div>
                                    </div>
                                    <button className='p-3 rounded-5 green-background fw-bold fs-5' type='submit' disabled={isFetching} >
                                        Register
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Col>
                    <Col className='d-flex d-none d-md-flex justify-content-center p-5' style={{ height: '100vh' }}>
                        <img src={Backdrop} alt="backdrop color" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Signup