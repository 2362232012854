import { createSlice } from "@reduxjs/toolkit";

const playerSlice = createSlice({
    name: "player",
    initialState: {
        isLoading: true,
        time: 1,
        audioBlob1: null,
        audioBlob2: null,
    },
    reducers: {
        updateTimer: (state, action) => {
            state.time = action.payload;
        },
        clearTime: (state) => {
            state.time = 0;
        },
        setAudio1: (state, action) => {
            state.isLoading = false;
            state.audioBlob1 = action.payload;
        },
        setAudio2: (state, action) => {
            state.isLoading = false;
            state.audioBlob2 = action.payload;
        },
        clearBlobs: (state) => {
            state.audioBlob1 = null;
            state.audioBlob2 = null;
        }
    }
})
export const {
    updateTimer, clearTime, setAudio1, setAudio2, clearBlobs
} = playerSlice.actions;

export default playerSlice.reducer;