import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { logOut } from "../Redux/Slices/AuthSlice";

const ProtectedRoute = ({ children }) => {
    const { isFetching, token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const checkToken = async () => {
            try {
                const result = jwtDecode(token)?.exp < Date.now() / 1000;
                if (result) {
                    dispatch(logOut());
                    navigate("/signin");
                }
            } catch (e) {
                console.log(e);
            }
        };

        checkToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, navigate]);

    if (!token && !isFetching) {
        return <Navigate to="/signin" />;
    }

    return children;
};

export default ProtectedRoute;
