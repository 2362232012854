import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { capitalizeFirstLetter } from '../utils/Functions';
import { useDispatch } from 'react-redux';
import { clearTime, clearBlobs } from '../Redux/Slices/PlayerSlice';

const Button2 = ({ label, index, func }) => {
    const dispatch = useDispatch();
    const handleChange = () => {
        dispatch(clearTime());
        dispatch(clearBlobs());
        if (index === undefined) {
            func();
        } else {
            func(label)
        }
    }
    return (
        <div onClick={() => handleChange()}>
            <Dropdown.Item className='text-black text-truncate w-100'>
                {capitalizeFirstLetter(label)}
            </Dropdown.Item>
        </div>
    )
}

export default Button2