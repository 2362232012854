import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Backdrop from '../Assets/backdrop.png';
import Logo from '../Assets/logo-wikiStrings.svg';

const Verification = () => {
    const navigate = useNavigate();
    const [seconds, setSeconds] = React.useState(30);
    React.useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [seconds]);

    return (
        <div className='overflow-hidden' style={{ 'backgroundColor': "rgb(14,17,19)", minheight: '100vh' }}>
            <Container fluid>
                <Row>
                    <Col className='px-md-5'>
                        <img src={Logo} alt="Logo" />
                        <div className='d-flex p-5 justify-content-center flex-column w-100 text-white h-100 poppins'>
                            <h1>
                                Verification
                            </h1>
                            <form className='p-md-5' autoComplete={"off"}>
                                <p className='mb-5'>
                                    Enter your 4 digits code that you received on your email.
                                </p>
                                <Col className='d-flex flex-column gap-5 '>
                                    <Row fluid className='d-flex flex-row gap-3 justify-content-center'>
                                        <input type="text" className='form-control bg-transparent text-white' style={{ width: "60px", height: '60px' }} />
                                        <input type="text" className='form-control bg-transparent text-white' style={{ width: "60px", height: '60px' }} />
                                        <input type="text" className='form-control bg-transparent text-white' style={{ width: "60px", height: '60px' }} />
                                        <input type="text" className='form-control bg-transparent text-white' style={{ width: "60px", height: '60px' }} />
                                    </Row>
                                    <p className='text-center text-danger'>{`00:${seconds}`}</p>
                                    <button onClick={() => navigate('newpassword')} className='p-3 rounded-5 green-background fw-bold fs-5 text-white' type='submit'>
                                        Continue
                                    </button>
                                    <p className='text-center'>
                                        If you didn’t receive a code! <em className='text-danger'>Resend</em>
                                    </p>
                                </Col>
                            </form>
                        </div>
                    </Col>
                    <Col className='d-flex d-none d-md-flex justify-content-center p-5' style={{ height: '100vh' }}>
                        <img src={Backdrop} alt="backdrop color" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Verification