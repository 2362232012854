import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectGuitar, selectGuitar2 } from '../Redux/Slices/GuitarSlice';
import { Button } from 'react-bootstrap';
import { FaAmazon } from "react-icons/fa";

function Card(props) {
    const { guitar2, guitar } = useSelector((state) => state.guitar);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handlePlaying = () => {
        if (props?.data?._id === guitar?._id) {
            window.open(props?.data?.affiliateLink, '_blank')
        }
        if (props.selector) {
            if (Object.keys(guitar2).length === 0) {
                dispatch(selectGuitar(props?.data));
                dispatch(selectGuitar2(guitar));
            } else {
                dispatch(selectGuitar2(guitar));
                dispatch(selectGuitar(props?.data));
            }
        } else {
            dispatch(selectGuitar(props?.data));
            navigate('/playing');
        }
    }


    return (
        <div
            onClick={() => handlePlaying()}
            className={`text-black text-center p-1 d-flex glow-${props?.data?._id === guitar?._id ? 'green' : props?.data?._id === guitar2?._id ? 'grey' : 'yellow'} rounded mb-3 flex-column justify-content-center align-items-center flex-grow bg-transparent bg-body-secondary card-height-string w-100`}
            
        >
            <img
                src={props?.data?.guitarPic}
                alt="Card"
                className="string-img"
                style={{ borderRadius: '12px' }}
            />
            <div className="w-100">
                <h6 className="text-truncate w-100">{props?.data?.guitarName}</h6>
            </div>
            <Button
                type="button"
                variant={
                    props?.data?._id === guitar?._id
                        ? 'success'
                        : props?.data?._id === guitar2?._id
                            ? 'secondary'
                            : 'warning'
                }
                onClick={() => handlePlaying()}
                size='sm'
                className='compare-btn-size'
               
            >
                {props?.data?._id === guitar?._id ? (
                    <div className="d-flex justify-content-center align-items-center compare-font-size" style={{ fontSize: '0.8rem' }}>
                        <span className="w-full"><FaAmazon /> Get info</span>
                    </div>
                ) : (
                    <span className='compare-font-size'>Compare</span>
                )}
            </Button>
        </div>
    );
}

export default Card;
