import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from '../Redux/Slices/AuthSlice'
import wikiStrings from '../Assets/logo-wikiStrings.svg';

const Topbar = () => {
    const { token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const handleLogout = async () => {
        dispatch(logOut());
    }
    const navigate = useNavigate();
    return (
        <Navbar variant="light" expand="lg" className="navbar-light fixed-top border-bottom px-5 py-2 mask-custom"> {/* Set the background color to dark */}
            {/* <div className="px-5 d-flex justify-content-between w-100"> */}
            <Navbar.Brand>
                <Link to={'/'}>
                    <Image fluid src={wikiStrings} alt="wiki strings" style={{ maxHeight: '60px' }} />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className='ps-2'>
                <Nav className="me-auto ms-auto  quicksand gap-4 ">
                    <Link to={'/'}> <strong>Home</strong> </Link>
                    <Link to={'/aboutus'}> <strong>About Us</strong></Link>
                    <Link to={'/mission'}> <strong>Mission</strong> </Link>
                    <Link to={'/howitworks'}> <strong>How It Works</strong> </Link>
                    <Link to={'/aboutus'}> <strong>Privacy Policy</strong> </Link>

                </Nav>
                {
                    !token ? <Nav className='d-flex gap-2 my-2'>
                        <Button variant="success" onClick={() => navigate('/signin')}>
                            Login
                        </Button>

                        <Button variant="light" onClick={() => navigate('/signup')}>Signup</Button>
                    </Nav> :
                        <Nav className='d-flex gap-2'>
                            {/* <Button variant="success" onClick={() => navigate('/profile')}>
                                My Profile
                            </Button> */}
                            <Button variant="danger" onClick={() => handleLogout()}>
                                Logout
                            </Button>
                        </Nav>
                }


            </Navbar.Collapse>
            {/* </div> */}
        </Navbar>
    )
}

export default Topbar