import axios from '../utils/axiosInstance';
import {
    loginStart,
    loginSuccess,
    loginFailure,
    registerSuccess,
    registerStart,
    registerFailure,
} from '../Redux/Slices/AuthSlice';

import {
    getGuitars,
    getGuitarsFailure,
    getGuitarsSuccess
} from '../Redux/Slices/GuitarSlice';

import {
    updateStart,
    updateSuccess,
    updateFailure
} from './Slices/ProfileSlice';
import { GetAllGuitars as getAllFunc } from '../utils/Functions';

const token = localStorage.getItem("userToken");

export const login = async (dispatch, user) => {
    dispatch(loginStart());

    const { email, password } = user;
    try {
        const { data } = await axios.post("/api/user-login", { email, password });
        dispatch(loginSuccess(data));
    } catch (error) {
        dispatch(loginFailure(error?.response?.data?.error));
    }
};

export const signup = async (dispatch, user) => {
    dispatch(registerStart());

    const { fullname, email, mobile, password } = user;

    try {
        const { data } = await axios.post("/api/user-register", { fullname, email, mobile, password });
        dispatch(registerSuccess(data));
    } catch (error) {
        dispatch(registerFailure(error?.response?.data?.error));
    }
}

export const getAllGuitars = async (dispatch, slovedGuitar, loading) => {
    dispatch(getGuitars());

    try {
        // const [guitarsResponse, stringsData] = await Promise.all([
        //     axios.get("/api/data/guitar"),
        //     getAllStrings()
        // ]);
        getAllFunc(dispatch, slovedGuitar, loading);
        // const { data: guitarsData } = guitarsResponse;
        // dispatch(getGuitarsSuccess(guitarsData));
    } catch (error) {
        dispatch(getGuitarsFailure(error?.response?.data?.error));
    }
}

export const updateProfile = async (dispatch, user) => {
    dispatch(updateStart());
    const { fullname, email, mobile, password } = user;
    try {
        const { data } = await axios.put("/api/user/profile", { fullname, email, mobile, password }, {
            headers: {
                Authorization: `${token}`,
            },
        });
        dispatch(updateSuccess(data));
    } catch (error) {
        dispatch(updateFailure(error?.response?.data?.error));
    }
}

export const getAcousticGuitar = async (dispatch, keyword) => {
    dispatch(getGuitars());
    try {
        const { data } = await axios.get(`/api/data/guitar/acoustic?keyword=${keyword}`);
        dispatch(getGuitarsSuccess(data));
    } catch (error) {
        dispatch(getGuitarsFailure(error?.response?.data?.error));
    }
}

export const getElectricGuitar = async (dispatch, keyword) => {
    dispatch(getGuitars());
    try {
        const { data } = await axios.get(`/api/data/guitar/electric?keyword=${keyword}`);
        dispatch(getGuitarsSuccess(data));
    } catch (error) {
        dispatch(getGuitarsFailure(error?.response?.data?.error));
    }
}

